<template>
  <!-- v-bind='data.attrs' -->
  <!-- :class="[data.class, data.staticClass, 'kft-price']" -->
  <!-- :style='[data.style, data.staticStyle]' -->
  <!-- <div v-on='listeners'> -->
  <div>
    <!--@slot Custom regular price -->
    <!-- <slot v-bind='{ props }' name='regular'> -->
    <slot name="regular">
      <span class="font-semibold text-[14.88px]">
        {{ regular }}
      </span>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'Price',
  props: {
    /**
     * Regular/old price value. Crossed out if `special` is provided
     */
    regular: {
      type: [String, Number],
      default: null,
    },

    /**
     * Special price value
     */
    special: {
      type: [String, Number],
      default: null,
    },
  },
}
</script>
<style lang="postcss">
.kft-price {
  display: flex;
  flex-wrap: var(--price-flex-wrap, wrap);
  flex-direction: var(--price-flex-direction, row);
  align-items: var(--price-align-items, flex-end);
  justify-content: var(--price-justify-content);
}
</style>
